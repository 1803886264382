const project = [
    {
        title: "Soğukpınar Mahallesi'ne Taziye Evi Açıyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/SO%C4%9EUKPINAR%20MAHALLES%C4%B0%E2%80%99NE%20TAZ%C4%B0YE%20EV%C4%B0%20D%C3%9C%C5%9E%C3%9CK/FERT8643.jpg",
        description: "Çekmeköy Belediyesi olarak iyi günde kötü günde vatandaşlarımızın yanında olmaya devam ediyoruz. Soğukpınar Mahallesi'nde, Hacı Yüksel Erdoğan Camii’nin yapımı sırasında vatandaşlarımızın kendi bütçeleriyle tamamladığı, ancak sadece kaba inşaatı bitirilmiş olan cami bodrum katını, Başkan Orhan Çerkez’in talimatları doğrultusunda Fen İşleri Müdürlüğü olarak yeniden ele aldık. Temmuz ayında başlattığımız ince imalat çalışmaları kapsamında; mekanik, elektrik, sıhhi tesisat, doğrama, seramik gibi tüm detaylı işleri belediye personellerimiz özveriyle yapıyor. Ağustos ayı içerisinde tamamlanması planlanan çalışmalar sonucunda, bodrum katını konferans salonu ve taziye evi olarak düzenleyeceğiz. Vatandaşlarımızın ihtiyaçlarına yönelik bu çalışmamızla camii bodrum katını en verimli şekilde kullanıma sunacağız."
    },
    {
        title: "Soğukpınar Mahallesi'nde Komşularımızın İstekleri Üzerine Kurban Pazarı Kurulmayacak",
        image: "https://www.cekmekoy.bel.tr/upload/news/sogukpinar-halkindan-baskan-cerkez-e-tesekkur/sogukpinar-halkindan-baskan-cerkez-e-tesekkur_3043.jpeg",
        description: "Soğukpınar Mahallesi'nde vatandaşlarımızdan gelen yoğun talep ve istek üzerine, geçtiğimiz yıllarda kurulan kurban satış ve kesim alanı bu yıl kurulmayacak. Mahalle sakinlerimizin kötü koku ve hijyenik olmayan ortam konusundaki hassasiyetlerini dikkate alarak bu kararı aldık. Komşularımızın İstekleri Bizim Önceliğimiz diyerek bu müjdeyi paylaştık. Sağlık ve çevre konularında mahalle halkının yaşam kalitesini artırmayı hedefliyoruz. Vatandaşlarımızın sağlığı ve refahı her zaman önceliğimizdir."
    },
]

export default project