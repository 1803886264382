const project = [
    {
        title: "Doğa Park’ta Vatandaşa Hizmet Dönemi Başlıyor",
        image: "https://www.cekmekoy.bel.tr/upload/files/DO%C4%9EA%20PARK%E2%80%99TA%20VATANDA%C5%9EA%20H%C4%B0ZMET%20D%C3%96NEM%C4%B0%20d%C3%BC%C5%9F%C3%BCk/1_8%20-%20Foto.jpg",
        description: "Halka ait alanları halkın yararına kullanılacak işletmelere dönüştürme çalışmalarımız tüm hızıyla devam ediyor. Vatandaşlardan gelen yoğun talepler doğrultusunda, mülkiyeti ve kullanım hakkı belediyemize ait olan, ancak özel işletmeler tarafından kullanılan alanları halka açmak için çalışmalarımıza başladık. Bu kapsamda, Çekmeköy’ün en yoğun noktalarından biri olan Şehit Üsteğmen Arif Kalafat Doğa Parkı’nda yer alan özel işletmenin tahliye sürecini başlattık. Park içerisinde oluşturulacak yeni sosyal tesiste; uygun fiyatlı yemek, çay ve kahve hizmetleri sunulacak, böylece vatandaşlarımız yüksek fiyat politikasından kurtulmuş olacak. Ayrıca, kadınlar için el emeği ürünlerin satışını yapabilecekleri pazar alanı oluşturacağız. Açık ve kapalı olarak planlanan bu alanlarda kadınlar, el emeği ürünlerini ortak daimi satış noktası ve bireysel satış stantları aracılığıyla sunarak aile ekonomilerine katkı sağlayacak. Bunun yanı sıra, elişi ürünleri için ortak üretim atölyesi ve gıda ürünleri için ortak üretim mutfağı da kurulacak. Bu yeni sosyal donatı alanları ile Doğa Park’ı halkımızın faydasına sunarak, Çekmeköy’ü daha yaşanabilir ve erişilebilir bir ilçe haline getirmeyi hedefliyoruz. Her zaman vatandaşımızın yanındayız!"
    },
    {
        title: "Hamidiye Mahallesi'nde Emekliler Lokalimizin Temelini Attık",
        image: "https://www.cekmekoy.bel.tr/upload/files/1EMEKLİLER%20İÇİN%20YEPYENİ%20BİR%20YAŞAM%20ALANI%20DÜŞÜK/FERT8455.jpg",
        description: "Emekli vatandaşlarımızın keyifli ve kaliteli zaman geçirebileceği Emekliler Lokali’nin temelini Hamidiye Mahallesi’ndeki Mecidiye Parkı’nda attık. İlçemize yeni sosyal donatı alanları kazandırmaya devam ediyoruz. 250 m² alan üzerine tasarlanan Emekliler Lokali, açık ve kapalı alanlarıyla 120 vatandaşa aynı anda hizmet verecek. Modern ve emeklilerimizin ihtiyaçlarına uygun olarak dizayn edilen lokalde, teras alanı, mescitler, lavabolar, mutfak, kafeterya, kadın ve erkekler için ayrı okuma odaları, oturma odaları ve kütüphane yer alacak. Ayrıca, emekli vatandaşlarımız için özel kültür sanat programları, atölyeler ve danışmanlık hizmetleri sunacağız. Kısa sürede tamamlamayı planladığımız bu proje ile emeklilerimize huzurlu ve sosyal bir yaşam alanı sunacağız. Her zaman olduğu gibi, emeklilerimizin yanında olmaya devam ediyoruz!"
    },
    {
        title: "Hamidiye Mahallesi Öğretmenler Sitesi’nde Sorunları Yerinde Dinledik",
        image: "https://www.cekmekoy.bel.tr/upload/files/BA%C5%9EKAN%20%C3%87ERKEZ%E2%80%99DEN%20%C3%96%C4%9ERETMENLER%20S%C4%B0TES%C4%B0NE%20Z%C4%B0YARET%20d%C3%BC%C5%9F%C3%BCk/FERT7711.jpg",
        description: "Hamidiye Mahallesi’nde bulunan Öğretmenler Sitesi’nde komşularımızla bir araya geldik. Sosyal Belediyecilik anlayışımızla, site sakinlerinin beklentilerini, şikayetlerini ve yaşadıkları bölgeyle ilgili taleplerini dinledik. Altyapı, üstyapı, park ve yeşil alan düzenlemeleri, ulaşım ve trafik gibi çeşitli konulardaki öneri ve istekleri not alarak, ilgili birim müdürlerimize çözüm için talimatlarımızı verdik. Vatandaşlarımızın sorunlarını yerinde dinleyerek, daha hızlı ve etkili çözümler üretiyoruz. Her zaman yanınızda olmaya ve sizler için çalışmaya devam edeceğiz!"
    },
]

export default project