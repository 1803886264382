const project = [
    {
        title: "4 Mahallemizde Ücretsiz Öğrenci Servisleri Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/files/k%C3%B6ylere%20%C3%BCcretsiz%20servis/servis%20(1).jpg",
        description: "Ömerli, Koçullu, Sırapınar ve Hüseyinli mahallelerimizde vatandaşlarımızla bir araya gelerek, öğrencilerimizin okullarına ulaşımda yaşadığı sorunları dinledik. Eğitim aksatılmamalı dedik ve hafta içi her sabah saat 06:30’da mahalle meydanları ve muhtarlıklar önünden Madenler Meydanı’na ücretsiz öğrenci servisleri başlattık. Gençler bizim başımızın tacı; onların yanındayız ve her zaman destekçileri olacağız. Öğrenci kardeşlerimize hayırlı olsun!"
    },
    {
        title: "Hüseyinli ve Sırapınar Mahallelerinde Komşularımızın Taleplerini Dinledik",
        image: "https://www.cekmekoy.bel.tr/upload/files/s%C4%B1rap%C4%B1nar%20h%C3%BCseyinli%20halk%20bulusmasi/5U8A8894.jpg",
        description: "Halk Günü Değil, Halkın Günü sloganıyla başlattığımız mahalle buluşmalarının devamında, Hüseyinli ve Sırapınar Mahalle sakinleriyle Sırapınar Köy Konağı’nda bir araya geldik. Toplantıya belediye başkan yardımcıları, birim müdürleri, Hüseyinli Muhtarı İlhan Kılınç, Sırapınar Muhtarı Nejat Girgin ve vatandaşlarımız katıldı. İnternet altyapısı, sokak hayvanları, imar durumu, çevre, spor alanları, parklar, eğitim, ulaşım, sinek ilaçlaması ve sağlık ocağı gibi konularda gelen istek, talep ve şikayetleri tek tek not aldık. Vatandaşlarımızla buluşmada, Siyaset hizmet aracıdır. Eğer iyi yaparsanız hizmete dönüşür, herkesi memnun eder diyerek, eski yönetimin yerine gelmemizin ardından bu sorunları çözmek için gerekli adımları atacağımızı belirttik. Bölgedeki sorunlar için gerekli görüşmeleri yapacağımızı ve istihdam konusunda da gerekli hamleleri yapacağımızı ifade ettik. Vatandaşlarımıza bu süreçte gösterdikleri ilgi ve destek için teşekkür ediyorum, sorunları birlikte çözeceğiz."
    }
]

export default project