const project = [
    /*
        {
            title: "",
            image: "",
            description: ""
        },
    */
    ]
    
export default project