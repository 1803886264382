const project = [
    {
        title: "Merkez Mahallemizde Yeni Kreş ve Gündüz Bakımevinin Temelini Attık",
        image: "https://www.cekmekoy.bel.tr/upload/news/cekmekoy-un-yeni-kres-ve-gunduz-bakim-evinin-temel-atma-toreni-gerceklesti/cekmekoy-un-yeni-kres-ve-gunduz-bakim-evinin-temel-atma-toreni-gerceklesti_9211.jpg",
        description: "Çekmeköy Belediyesi olarak, eğitim alanında kalıcı eserler kazandırmaya devam ediyoruz. Okul öncesi çocukların eğitim ihtiyacını karşılamak ve çalışan annelere destek sağlamak amacıyla Merkez Mahallesi’nde Yunus Emre Caddesi ve Engin Sokak kesişiminde yer alacak kreş ve gündüz bakımevinin temel atma törenini gerçekleştirdik. Toplam 979 m² alan üzerine inşa edilecek olan ve 4 kattan oluşacak bu modern tesisimizde, 8 sınıfta toplamda 100 öğrenci eğitim alacak. Çağdaş eğitim standartlarına uygun olarak tasarlanan kreş ve gündüz bakım evimizde, uyku odası, yemekhane, idari birimler, iç ve dış oyun alanları gibi çocukların eğitimi, gelişimi ve güvenliğine önem veren tüm unsurlar yer alacak. Bu projeyle hem aile ekonomisine katkı sağlamayı hem de çocuklarımıza güvenli ve kaliteli bir eğitim ortamı sunmayı hedefliyoruz. Çekmeköy Belediyesi olarak, geleceğe değer katan projelerimizle ilçemize hizmet vermeye devam edeceğiz!"
    },
    {
        title: "Merkez Mahallemizdeki Kapalı Pazar Yeri Yakında Hizmetinizde Olacak",
        image: require("./images/merkez/kapalipazar.jpg"),
        description: "Merkez Mahallesi’nde, vatandaşlarımızın ihtiyaçlarını karşılayacak modern ve konforlu pazar yerimizi yakında hizmetinize açıyoruz. Yeni pazar yeri, geniş ve ferah alanlarıyla alışverişi daha keyifli ve düzenli hale getirecek. Hem esnafımız hem de halkımız için modern altyapısı, hijyenik koşulları ve güvenli alışveriş imkanı sunan bu pazar yeri, mahallemizin sosyal hayatına da canlılık katacak. Çekmeköy’ün standartlarını yükseltmeye ve sizler için en iyi hizmetleri sunmaya devam ediyoruz. Yeni pazar yerimiz, tüm mahalle sakinlerimize hayırlı olsun!"
    },
    {
        title: "Merkez Mahallemizdeki Yuvamız Çekmeköy Gündüz Bakımevi'ni Hizmete Açtık",
        image: "https://www.cekmekoy.bel.tr/upload/news/cekmekoy-belediyesi-nin-ilk-gunduz-bakimevinde-on-kayitlar-basladi/cekmekoy-belediyesi-nin-ilk-gunduz-bakimevinde-on-kayitlar-basladi_690.jpg",
        description: "Merkez Mahallemizde çocuklarımızın güvenli ve kaliteli bir ortamda büyümeleri için Yuvamız Çekmeköy Gündüz Bakımevi’ni hizmete açtık. Modern, donanımlı ve çocukların ihtiyaçlarına uygun olarak tasarlanan bakımevimiz, miniklerin gelişimlerine katkı sağlayacak eğitimler ve aktiviteler sunarak ailelerimizin yükünü hafifletmeyi amaçlıyor. Çekmeköy’de her çocuğumuzun en iyi şartlarda bakım ve eğitim alması için çalışmaya devam ediyoruz."
    },
    {
        title: "20 Yıllık Koku Sorununu 3 Ayda Çözüme Kavuşturduk",
        image: require("./images/merkez/merkezmahdere.png"),
        description: "Merkez Mahallesi Özlem Sokak’ta yıllardır süren büyük bir sorunu çözüme kavuşturduk. 20 yıldır oturdukları evlerde kokudan camlarını açamayan, çocuklarını gönül rahatlığıyla sokakta oynatamayan komşularımızın yaşadığı kanalizasyon sorunu, İSKİ Genel Müdürümüzle yaptığımız görüşmeler ve girişimlerimiz sonucunda sadece 3 ayda çözüldü. Seçim çalışmalarım sırasında mahalle sakinlerine söz vermiştim; sözümüzü tuttuk ve bu büyük problemi ortadan kaldırdık. Artık Özlem Sokak’ta çocukların yüzü gülüyor, komşularımız mutlu. Evlatlarımızın yüzü gülüyorsa dünya bir dakikalığına dursun. Çekmeköy için, mahallelerimiz için her zaman çalışmaya devam edeceğiz!"
    },
]

export default project