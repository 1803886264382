const project = [
    {
        title: "4 Mahallemizde Ücretsiz Öğrenci Servisleri Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/files/k%C3%B6ylere%20%C3%BCcretsiz%20servis/servis%20(1).jpg",
        description: "Ömerli, Koçullu, Sırapınar ve Hüseyinli mahallelerimizde vatandaşlarımızla bir araya gelerek, öğrencilerimizin okullarına ulaşımda yaşadığı sorunları dinledik. Eğitim aksatılmamalı dedik ve hafta içi her sabah saat 06:30’da mahalle meydanları ve muhtarlıklar önünden Madenler Meydanı’na ücretsiz öğrenci servisleri başlattık. Gençler bizim başımızın tacı; onların yanındayız ve her zaman destekçileri olacağız. Öğrenci kardeşlerimize hayırlı olsun!"
    },
    {
        title: "Ömerli ve Koçullu Mahallelerinde Komşularımızın Taleplerini Dinledik",
        image: "https://www.cekmekoy.bel.tr/upload/files/BA%C5%9EKAN%20%C3%87ERKEZ%20%C3%96MERL%C4%B0%20VE%20KO%C3%87ULLU%20MAHALLELER%C4%B0N%C4%B0N%20SAK%C4%B0NLER%C4%B0YLE%20BULU%C5%9ETU%20d%C3%BC%C5%9F%C3%BCk/FERT8034.jpg",
        description: "Çekmeköy’de 21 mahallede düzenleyeceğimiz Halk Buluşmaları'nın ilkini Ömerli ve Koçullu Mahallelerinde gerçekleştirdik. Vatandaşlarımızın isteklerine doğrudan cevap vermek ve sorunlara yerinde çözümler üretmek amacıyla bu projeye başladık. Ömerli Görsel Sanatlar Merkezi’nde düzenlediğimiz ilk buluşmamıza, belediye meclis üyelerimiz, Ömerli Mahalle Muhtarı Hüseyin Uygun, Koçullu Mahalle Muhtarı Mitat Şimşek, Hüseyinli Muhtarı İlhan Kılınç, Sırapınar Muhtarı Nejat Girgin, belediye birim müdürleri ve mahalle sakinleri katıldı. Vatandaşlarımızın taleplerini dinleyerek, hep birlikte çözüm yolları arayacağız. Her zaman yanınızda olmaya ve sizin için çalışmaya devam edeceğiz."
    },
]

export default project