const project = [
    {
        title: "15 Yıllık Yol Sorununu 3 Günde Çözüme Kavuşturduk",
        image: "https://www.cekmekoy.bel.tr/upload/files/alemda%C4%9F%20yol%20ac%C4%B1lmas%C4%B1/alemdag%20yol%20acilmasi%20(5).jpg",
        description: "Göreve geldiğimiz ilk günden itibaren vatandaş ve çözüm odaklı belediyeciliği ön planda tutarak, mahalle buluşmalarında aldığımız talepleri hızlı ve kalıcı çözümlerle sonuçlandırıyoruz. Alemdağ-Çatalmeşe Halkın Buluşması'nda mahalle sakinlerinden gelen talepler üzerine, 15 yıldır çözüm bekleyen Cevizlik Sokak ve 130. Sokak’ta yol açılmasının yasal prosedürlerini hızla tamamlayarak 3 gün içerisinde sorunu çözüme kavuşturduk. Vatandaşlarımızın yıllardır beklediği bu sorunu çözmekten büyük mutluluk duyuyoruz; her zaman yanınızda olmaya ve sizler için çalışmaya devam edeceğiz!"
    },
    {
        title: "Yuvamız Çekmeköy Alemdağ Çocuk Gündüz Bakımevi Hizmete Girdi",
        image: "https://www.cekmekoy.bel.tr/upload/files/WhatsApp%20Image%202024-07-26%20at%2013-05-36.jpg",
        description: "Çekmeköy’de ailelerimizin ve çocuklarımızın yanında olmaya devam ediyoruz. Yuvamız Çekmeköy Alemdağ Çocuk Gündüz Bakımevi’ni hizmete açarak, minik yavrularımızın güvenli ve kaliteli bir ortamda bakımını sağlıyoruz. Modern ve donanımlı bir şekilde tasarlanan bakımevimizde, çocuklarımızın gelişimlerine katkı sunacak eğitimler ve aktiviteler düzenleyerek ailelerimizin hayatını kolaylaştırmayı hedefliyoruz. Çekmeköy’de çocuklarımızın en iyi şartlarda büyümesini sağlamak için çalışmaya devam edeceğiz. Yeni bakımevimiz, ilçemize ve ailelerimize hayırlı olsun."
    },
    {
        title: "Alemdağ ve Çatalmeşe Mahallelerinde Komşularımızın Taleplerini Dinledik",
        image: "https://www.cekmekoy.bel.tr/upload/news/alemdag-ve-catalmese-mahalle-sakinlerini-baskan-cerkez-dinledi/alemdag-ve-catalmese-mahalle-sakinlerini-baskan-cerkez-dinledi_7559.jpeg",
        description: "Halk Günü Değil, Halkın Günü sloganıyla başlattığımız mahalle buluşmalarının üçüncüsünü Alemdağ ve Çatalmeşe Mahallelerinde gerçekleştirdik. Alemdağ Kültür Merkezi’nde düzenlediğimiz bu buluşmada, Belediye Başkan Yardımcıları, birim müdürleri, Alemdağ Mahalle Muhtarı Cengiz Yıldız, Çatalmeşe Mahalle Muhtarı Hakan Eren ve mahalle sakinleriyle bir araya geldik. Vatandaşlarımızdan yol, okul, sosyal tesisler, taziye evi, kültürel etkinlikler, spor tesisleri, pazar yeri, mesire alanları, imar planları, iskân durumu, tapu, kültür merkezi kullanımı, istihdam, ev kadınları için sosyal alanlar, Ubeydullah Cami desteği, ormanların halka açılması, trafik, ilaçlama, hafriyat, sokak temizliği, kentsel dönüşüm, market denetimleri ve ulaşım gibi birçok konuda talep, istek, şikâyet ve önerilerini dinleyip notlarımızı aldık. Mahalle sakinlerimizin her konuda yanında olmaya devam edeceğiz."
    },
]

export default project