import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import Projects from './Projects'
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

createRoot(document.getElementById("root")).render(
  <Router>
    <Routes> {/* Wrap your routes in a <Routes> component */}
      <Route path="/mahalle/:mah" element={<Projects />} />
      <Route path="/" element={<App />} />
    </Routes>
  </Router>,
);

reportWebVitals();