import React, { useEffect, useState } from 'react';
import exportProject from './projects/projectsHelper';
import './App.css';
import { useParams, useNavigate } from "react-router-dom";
import Mahalleler from './mahalleler'
import {Twitter, Facebook, Whatsapp, Instagram, Youtube} from './assets/Icons'

function Projects() {
	const navigate = useNavigate();

	const [data, setData] = useState([])
	const [mahData, setMahData] = useState([])
	const [collapsible, setCollapsible] = useState([])
	const [currentMahIndex, setCurrentMahIndex] = useState()
	const { mah } = useParams();
	
	const fetchProjectData = async () => {
		try {
			setMahData(Mahalleler.filter(m => m.slug === mah)[0])
			setCurrentMahIndex(Mahalleler.findIndex(m => m.slug === mah))
			
			const projectData = exportProject(mah);
			if (projectData) {
				setCollapsible(Array(projectData.length).fill(false));
				setData(projectData)
			} else {
				const defaultData = exportProject('alemdag')
				setData(defaultData)
			}
		} catch (error) {
			console.error('Error fetching data:', error);
			throw error;
		}
	};
	
	useEffect(() => {
		fetchProjectData()
	}, [mah])

	function showDetails(index) {
		setCollapsible(prevState => {
			const updatedCollapsible = [...prevState]; 
			updatedCollapsible[index] = !updatedCollapsible[index];
			return updatedCollapsible;
		  });
	}
	
	
return (
	<>
	{data && 
	<div className="App">
			<header style={{position:'relative', padding:0}}>
				<div className='filter-header'></div>

				<img alt='header' width={'100%'} className='header-image' src={require('./assets/header.png')}></img>

				<div className='header-container'>
					<a href='/' className='logo'>
						<img alt='logo' width={140} src={require('./assets/cekmekoy.png')}></img>
						<h3><b >21 MAHALLE</b><br/>YATIRIMLARLA<br/>GÜÇLENİYOR</h3>
					</a>
				</div>

				<div className='mah-header'>
					<div className='mah-image'>
						<img alt='mah' width={100} src={mahData.image}></img>
					</div>
					<div className='mah-title'>
						<h1>{mahData.mahalle}</h1>
						<h3>YATIRIMLARLA GÜÇLENİYOR</h3>
					</div>

					<div className='header-buttons'>
						{currentMahIndex >= 0 &&
							<button onClick={() => navigate(`/mahalle/${currentMahIndex === 0 ? Mahalleler[Mahalleler.length-1].slug : Mahalleler[currentMahIndex-1].slug}`)}>
								<img alt='prev-arrow' id='arrow-icon' style={{transform: "rotateZ(-90deg)"}} src={require('./assets/arrow-icon.svg').default} />
								{currentMahIndex === 0 ? Mahalleler[Mahalleler.length-1].mahalle : Mahalleler[currentMahIndex-1].mahalle}
								<img alt='prev-mah' id='mahalle-icon' width={25} src={currentMahIndex === 0 ? Mahalleler[Mahalleler.length-1].image : Mahalleler[currentMahIndex-1].image}/>
							</button>
						}

						{currentMahIndex < Mahalleler.length &&	
							<button onClick={() => navigate(`/mahalle/${currentMahIndex === Mahalleler.length-1 ? Mahalleler[0].slug : Mahalleler[currentMahIndex+1].slug}`)}>
								<img alt='next-mah' id='mahalle-icon' width={25} src={currentMahIndex === Mahalleler.length-1 ? Mahalleler[0].image : Mahalleler[currentMahIndex+1].image}/>
								{currentMahIndex === Mahalleler.length-1 ? Mahalleler[0].mahalle : Mahalleler[currentMahIndex+1].mahalle}
								<img alt='next-arrow' id='arrow-icon' style={{transform: "rotateZ(90deg)"}} src={require('./assets/arrow-icon.svg').default}/>
							</button>
						}
					</div>
				</div>


			</header>
			
			<div className='content-area'>
				<div className='project-container'>
					<h1 id='main-title'>Yaptıklarımız</h1>
				</div>
				<div className='project-container'>
				{
					data.map((project, idx) =>
					<div key={idx} className='project-item'>
						<img onClick={() => showDetails(idx)} alt={project.title} id='project-image' src={project.image}/>
						<div onClick={() => showDetails(idx)} className='project-details'>
							<div className='project-title'>{project.title}</div>
							<div className='project-icon'><img alt='project-arrow' style={collapsible[idx] ? {transform: "scaleY(-1)", transition:'all 0.5s'} : { transition:'all 0.5s'}} src={require("./assets/ctrl.svg").default}/></div>
						</div>

						{collapsible[idx] &&
						<div onClick={() => showDetails(idx)} className='project-description'>
							<p>
								{project.description}
							</p>
						</div>
						}

						<div className='share-container'>
							<a href={`whatsapp://send?text=${project.title} ${window.location}`}>
								<Whatsapp color='#c3c3c3'></Whatsapp>
							</a>
							<a href={`https://twitter.com/intent/tweet?text=${project.title} ${window.location}`} target='_blank' >
								<Twitter color='#c3c3c3'></Twitter>
							</a>
							<a href={`https://www.facebook.com/share_channel/?link=${window.location}&source_surface=external_reshare&display&hashtag=%23çekmeköy%20%23orhançerkez`} data-image={project.image} data-title={project.title} data-description={project.description} target='_blank'>
								<Facebook color='#c3c3c3'></Facebook>
							</a>
						</div>
					</div>
				)
				}
				</div>
			</div>
		
			<footer style={{position:'relative', backgroundColor:'#dc3545'}}>
				<div className='footer-container'>
					<img alt='cekmekoy logo' height={70} src={require('./assets/cekmekoy.png')}></img>
					<div className='socials'>
						<a href='https://www.instagram.com/orhanncerkez/' target='_blank'>
							<Instagram></Instagram>
						</a>
						<a href='https://twitter.com/orhancerkeztr' target='_blank'>
							<Twitter></Twitter>
						</a>
						<a href='https://www.facebook.com/orhancerkeztr' target='_blank'>
							<Facebook></Facebook>
						</a>
						<a href='https://www.youtube.com/@OrhanCerkez' target='_blank'>
							<Youtube></Youtube>
						</a>
					</div>
				</div>
			</footer>
	</div>
	}
	</>
);
}

export default Projects;
