const mahalleler = [
	{mahalle: "ALEMDAĞ", slug: "alemdag", image: require('./assets/mahalleler/alemdag.svg').default},
	{mahalle: "AYDINLAR", slug: "aydinlar", image: require('./assets/mahalleler/aydinlar.svg').default},
	{mahalle: "CUMHURİYET", slug: "cumhuriyet", image: require('./assets/mahalleler/cumhuriyet.svg').default},
	{mahalle: "ÇAMLIK", slug: "camlik", image: require('./assets/mahalleler/camlik.svg').default},
	{mahalle: "ÇATALMEŞE", slug: "catalmese", image: require('./assets/mahalleler/catalmese.svg').default},
	{mahalle: "EKŞİOĞLU", slug: "eksioglu", image: require('./assets/mahalleler/eksioglu.svg').default},
	{mahalle: "GÜNGÖREN", slug: "gungoren", image: require('./assets/mahalleler/gungoren.svg').default},
	{mahalle: "HAMİDİYE", slug: "hamidiye", image: require('./assets/mahalleler/hamidiye.svg').default},
	{mahalle: "HÜSEYİNLİ", slug: "huseyinli", image: require('./assets/mahalleler/huseyinli.svg').default},
	{mahalle: "KİRAZLIDERE", slug: "kirazlidere", image: require('./assets/mahalleler/kirazlidere.svg').default},
	{mahalle: "KOÇULLU", slug: "kocullu", image: require('./assets/mahalleler/kocullu.svg').default},
	{mahalle: "MEHMET AKİF", slug: "mehmetakif", image: require('./assets/mahalleler/mehmetakif.svg').default},
	{mahalle: "MERKEZ", slug: "merkez", image: require('./assets/mahalleler/merkez.svg').default},
	{mahalle: "MİMAR SİNAN", slug: "mimarsinan", image: require('./assets/mahalleler/mimarsinan.svg').default},
	{mahalle: "NİŞANTEPE", slug: "nisantepe", image: require('./assets/mahalleler/nisantepe.svg').default},
	{mahalle: "ÖMERLİ", slug: "omerli", image: require('./assets/mahalleler/omerli.svg').default},
	{mahalle: "REŞADİYE", slug: "resadiye", image: require('./assets/mahalleler/resadiye.svg').default},
	{mahalle: "SIRAPINAR", slug: "sirapinar", image: require('./assets/mahalleler/sirapinar.svg').default},
	{mahalle: "SOĞUKPINAR", slug: "sogukpinar", image: require('./assets/mahalleler/sogukpinar.svg').default},
	{mahalle: "SULTANÇİFTLİĞİ", slug: "sultanciftligi", image: require('./assets/mahalleler/sultanciftligi.svg').default},
	{mahalle: "TAŞDELEN", slug: "tasdelen", image: require('./assets/mahalleler/tasdelen.svg').default},
]

export default mahalleler