import alemdag from './alemdag.js'
import aydinlar from './aydinlar.js'
import camlik from './camlik.js'
import catalmese from './catalmese.js'
import cumhuriyet from './cumhuriyet.js'
import eksioglu from './eksioglu.js'
import gungoren from './gungoren.js'
import hamidiye from './hamidiye.js'
import huseyinli from './huseyinli.js'
import kirazlidere from './kirazlidere.js'
import kocullu from './kocullu.js'
import mehmetakif from './mehmetakif.js'
import merkez from './merkez.js'
import mimarsinan from './mimarsinan.js'
import nisantepe from './nisantepe.js'
import omerli from './omerli.js'
import resadiye from './resadiye.js'
import sirapinar from './sirapinar.js'
import sogukpinar from './sogukpinar.js'
import sultanciftligi from './sultanciftligi.js'
import tasdelen from './tasdelen.js'



const mahalleler = {
    alemdag, aydinlar, camlik, catalmese, cumhuriyet, eksioglu, gungoren, hamidiye, huseyinli, kirazlidere, kocullu, mehmetakif, merkez, mimarsinan, nisantepe, omerli, resadiye, sirapinar, sogukpinar, sultanciftligi, tasdelen
};

export default function exportProject(mahalle) {
    return mahalleler[mahalle];
}

