import React, { useEffect, useState } from 'react';
import Mahalleler from './mahalleler'
import { useNavigate } from "react-router-dom";
import Mainprojects from './projects/main'

import {Twitter, Facebook, Whatsapp, Instagram, Youtube} from './assets/Icons'

import './App.css';

function App() {
	const navigate = useNavigate();
	const [collapsible, setCollapsible] = useState([])

	function showDetails(index) {
		setCollapsible(prevState => {
			const updatedCollapsible = [...prevState]; 
			updatedCollapsible[index] = !updatedCollapsible[index];
			return updatedCollapsible;
		  });
	}

	return (
		<div className="App">
			<div className='filter'></div>
			<header>
				<div className='header-container'>
					<a href='/' className='logo'>
						<img alt='logo' width={140} src={require('./assets/cekmekoy.png')}></img>
						<h3><b>21 MAHALLE</b><br/>YATIRIMLARLA<br/>GÜÇLENİYOR</h3>
					</a>
				</div>
			</header>

			<div className='content-area'>
				<video className='background-video' loop playsInline autoPlay="autoplay" muted preload='auto' 
				style={{position:'fixed', top:0, left:0, zIndex:-2, width:'100vw', objectFit:'cover', height:'100vh', filter:"blur(3px)", transform:'scale(1.05'}}>
					<source src={require('./assets/background.mp4')} type="video/mp4"/>
				</video>
				
				<div className='mah-buttons'>
					{
						Mahalleler.map((mah, idx) => 
							<button key={idx} onClick={() => navigate(`/mahalle/${mah.slug}`)} className='mah-button'> 
								<img src={mah.image} alt={mah.mahalle}></img> {mah.mahalle}
							</button>
						)
					}
				</div>
			</div>

			<div className='content-area'>
				<div className='project-container'>
					{
						Mainprojects.map((project, idx) =>
						<div style={{backgroundColor:'#fff'}} key={idx} className='project-item'>
							<img onClick={() => showDetails(idx)} alt={project.title} id='project-image' src={project.image}/>
							<div onClick={() => showDetails(idx)} className='project-details'>
								<div  className='project-title'>{project.title}</div>
								<div className='project-icon'><img alt='project-arrow' style={collapsible[idx] ? {transform: "scaleY(-1)", transition:'all 0.5s'} : { transition:'all 0.5s'}} src={require("./assets/ctrl.svg").default}/></div>
							</div>

							{collapsible[idx] &&
							<div onClick={() => showDetails(idx)} className='project-description'>
								<p>
									{project.description}
								</p>
							</div>
							}

							<div className='share-container'>
								<a href={`whatsapp://send?text=${project.title} ${window.location}`}>
									<Whatsapp className='social-icon' color='#c3c3c3'></Whatsapp>
								</a>
								<a href={`https://twitter.com/intent/tweet?text=${project.title} ${window.location}`} target='_blank' >
									<Twitter className='social-icon' color='#c3c3c3'></Twitter>
								</a>
								<a href={`https://www.facebook.com/share_channel/?link=${window.location}&source_surface=external_reshare&display&hashtag=%23çekmeköy%20%23orhançerkez`} data-image={project.image} data-title={project.title} data-description={project.description} target='_blank'>
									<Facebook className='social-icon' color='#c3c3c3'></Facebook>
								</a>
							</div>
						</div>
					)
					}
				</div>
			</div>

			<footer style={{marginTop:'400px'}}>
				<div className='footer-container'>
					<img alt='baskan' id='baskan-foto' src={require('./assets/baskan.png')}></img>
					<div className='socials'>
						<a href='https://www.instagram.com/orhanncerkez/' target='_blank'>
							<Instagram color='#013866'></Instagram>
						</a>
						<a href='https://twitter.com/orhancerkeztr' target='_blank'>
							<Twitter color='#013866'></Twitter>
						</a>
						<a href='https://www.facebook.com/orhancerkeztr' target='_blank'>
							<Facebook color='#013866'></Facebook>
						</a>
						<a href='https://www.youtube.com/@OrhanCerkez' target='_blank'>
							<Youtube color='#013866'></Youtube>
						</a>
					</div>
				</div>
			</footer>
		</div>
		);
	}
	
	export default App;
	