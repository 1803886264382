const project = [
    {
        title: "Sosyal Tesislerimizde İndirimli Fiyatlar ile Hizmet Veriyoruz: Çay ve Su 5 TL, Kahve 14 TL",
        image: require("./images/main/caykahve.png"),
        description: "Çamlık Millet Bahçesi Sosyal Tesisleri, Cemal Güngör Parkı içindeki Çamlık Kafe Sosyal Tesisleri, Av. Sıddık Eraslan Sosyal ve Spor Tesisi'nde bulunan Çekmeköy Kahvecisi ve Seyir Tepesi Çamlık Sosyal Tesisleri, vatandaşlarımızdan gelen talepler doğrultusunda yeni dönemde indirimli fiyatlarla hizmet verecek. Çay ve su fiyatlarını 5 TL, kahve fiyatını ise 14 TL olarak belirledik. Her zaman olduğu gibi, halkımızın memnuniyeti önceliğimizdir."
    },
    {
        title: "Gençlere Özel Ekonomik Menüler Sosyal Tesislerimizde",
        image: require("./images/main/genclik.png"),
        description: "Çekmeköy Belediyesi bünyesindeki tüm sosyal tesislerde, gençlerimize özel ekonomik menüler sunmaya başladık. Margarita Pizza Menü 149 TL, Izgara Köfte Menü 149 TL, Hamburger Menü 139 TL, Tavuk Wrap Menü 150 TL, Combo Tabağı 99 TL ve Köri Soslu Tavuk Menü 120 TL olarak belirlenen bu yeni gençlik menüleriyle hem lezzetli hem de uygun fiyatlı seçenekler sunuyoruz. Gençlerimizin sosyal tesislerimizde keyifle vakit geçirmesi için çalışmalarımıza devam ediyoruz. Afiyet olsun!"
    },
    {
        title: "Milli Aracımız TOGG Şehit ve Gazi Ailelerinin Hizmetinde",
        image: "https://www.cekmekoy.bel.tr/upload/files/TOGG%20SEH%C4%B0T%20A%C4%B0LELERE%20H%C4%B0ZMET%20VERD%C4%B0%20N%C4%B0SAN/FERT0843.JPG",
        description: "Milli aracımız TOGG'u şehit ve gazi yakını ailelerimizin evlerine verdik. Bu doğrultuda, ailelerimiz mezarlık ziyaretleri, düğün, nişan ve sünnet gibi çeşitli organizasyonlarda ekiplerimize ulaşıyor ve aracımızı ücretsiz şekilde kullanıyor."
    },
    {
        title: "4 Mahallemizde Ücretsiz Öğrenci Servisleri Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/files/k%C3%B6ylere%20%C3%BCcretsiz%20servis/servis%20(1).jpg",
        description: "Ömerli, Koçullu, Sırapınar ve Hüseyinli mahallelerimizde vatandaşlarımızla bir araya gelerek, öğrencilerimizin okullarına ulaşımda yaşadığı sorunları dinledik. Eğitim aksatılmamalı dedik ve hafta içi her sabah saat 06:30’da mahalle meydanları ve muhtarlıklar önünden Madenler Meydanı’na ücretsiz öğrenci servisleri başlattık. Gençler bizim başımızın tacı; onların yanındayız ve her zaman destekçileri olacağız. Öğrenci kardeşlerimize hayırlı olsun!"
    },
    {
        title: "Öğrencilerimize Fındık Desteği Sağlıyoruz",
        image: require("./images/main/findik.png"),
        description: "Çekmeköy’de yaşayan Ordu ve Giresunlu hemşehrilerimizin fındıklarını alarak, okullarımızda öğrencilerimize dağıtıyoruz. Çocuklarımızın sağlıklı beslenmelerine katkıda bulunmak ve yerel üreticilerimizi desteklemek amacıyla başlattığımız bu proje ile, fındıkları öğrencilerimizin beslenme çantalarına koyuyoruz. Hemşehrilerimizin emeğini ve değerli ürünlerini çocuklarımızla buluşturarak, geleceğimizin teminatı olan gençlerimize sağlıklı ve doğal atıştırmalıklar sunuyoruz."
    },
    {
        title: "Çekmeköy Akademi YKS ve LGS Destek Kursları Başlıyor",
        image: "https://www.cekmekoy.bel.tr/upload/files/SOSYAL%20MEDYA%20-%20%C3%87EKMEK%C3%96Y%20AKADEM%C4%B0-01.jpg",
        description: "Çekmeköy Belediye Başkanı Orhan Çerkez’in öğrencilere verdiği söz tutuluyor; YKS ve LGS hazırlık kurslarımız açılıyor! Başvuruların ardından düzenlenecek sınav ile eğitim alacak öğrenciler belirlenecek. Şehit ve gazi yakınları ile anne veya babası vefat eden adayların başvurularına öncelik tanınacak. Çekmeköy’deki gençlerimize eğitim desteği sunarak, sınavlara en iyi şekilde hazırlanmalarını sağlamak için yanlarındayız!"
    },
    {
        title: "Taşdelen Kavşağı Sorununu Çözüme Kavuşturuyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/DEV%20PROJE%20%C4%B0%C3%87%C4%B0N%20GER%C4%B0%20SAYIM%20BA%C5%9ELADI%20%C5%9E%C4%B0LE%20YOLU%20TRAF%C4%B0%C4%9E%C4%B0%20TAR%C4%B0HE%20KARI%C5%9EIYOR%20tasar%C4%B1m/4.jpg",
        description: "Çekmeköy’de yaşayanların yıllardır kabusu haline gelen Taşdelen Kavşağı’nda sona yaklaşıyoruz. İBB Başkanı Ekrem İmamoğlu ve ilgili birimlerle yaptığımız görüşmeler sonucunda, bu dev proje 2025 yılının ilk yarısında tamamlanarak hizmete açılacak. Çekmeköy’ün trafik çilesini bitirmek için çalışmalarımızı hız kesmeden sürdürüyoruz."
    },
    {
        title: "Halkın Olan Halka Teslim Ediliyor: 180 İşgaliye Alanını Halkın Hizmetine Sunuyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/28.05.2024%20Mecidiye%20Park%C4%B1%20Ba%C5%9Fkan%20R%C3%B6portajj/FERT2474.jpg",
        description: "Çekmeköy halkından gelen yoğun talepler üzerine, belediyemize ait mülkiyet ve kullanım hakları özel işletmeler veya şahıslar tarafından işgal edilen toplam 180 alanı geri kazanmak için harekete geçtik. 2886 Devlet İhale Kanunu'nun 75’inci maddesi gereğince, 24 Nisan 2024’te bu işgaliye alanlarının boşaltılması için 180 ilgiliye resmi yazılar gönderildi.Bu alanlar arasında, halkımızın kullanımına sunulması konusunda yoğun taleplerin olduğu Doğa Park, Mecidiye Parkı ve Merkez Mahallesi İspinoz Kafe'nin yanı sıra, spor tesisleri, havuzlar, parklar, yeşil alanlar, halk ekmek büfeleri, çiçekçiler, özel okul kullanım alanları, basket sahaları, lojmanlar, binalar, okullar, dükkânlar ve dini tesisler de yer alıyor.Yaptığımız bu çalışma ile toplamda 180 işgaliye alanını yeniden değerlendirerek, ticari amaçlarla kullanılan bu yerleri Çekmeköy halkının ihtiyaç ve talepleri doğrultusunda kamu yararına sunmayı planlıyoruz. Çekmeköy Belediyesi olarak, ilçemizin her köşesinde halkımıza hizmeti en ön planda tutmaya ve yaşam kalitesini artırmaya devam edeceğiz!"
    },
    {
        title: "Cemaatlere ve Vakıflara Hizmeti Bitirdik. Çekmeköy Halkına Hizmeti Getirdik",
        image: "https://www.cekmekoy.bel.tr/upload/files/%C3%87EKMEK%C3%96Y%20BELED%C4%B0YE%20MECL%C4%B0S%C4%B0%20YEN%C4%B0%20D%C3%96NEM%20%C4%B0LK%20TOPLANTISINI%20GER%C3%87EKLE%C5%9ET%C4%B0RD%C4%B0%20D%C3%9C%C5%9E%C3%9CK/FERT8599.jpg",
        description: "Göreve geldiğimizde Halkın Belediyesi Halkın Hizmetinde anlayışıyla yola çıktık. Cemaatlere ve vakıflara verilen hizmetleri sonlandırarak tüm kaynaklarımızı Çekmeköy halkının ihtiyaçlarına yönlendirdik. İlçemizde, vatandaşlarımızın taleplerine ve beklentilerine doğrudan cevap veren, sosyal belediyecilik anlayışıyla hareket ediyoruz. Artık tüm gücümüzle Çekmeköy halkına hizmet ediyor, ilçemizin her köşesine fayda sağlamayı amaçlıyoruz. Halkımıza sunduğumuz hizmetlerin, yaşam kalitesini artırması için durmaksızın çalışmaya devam edeceğiz!"
    },
    {
        title: "Kendi Atölyemizde Üretim Yaparak Tasarruf Sağlıyoruz",
        image: require("./images/main/kademe.png"),
        description: "Ülkemizin içinde bulunduğu zor ekonomik koşullarda, kamu kaynaklarını en verimli şekilde kullanmanın önemini biliyoruz. Bu kapsamda, Çekmeköy’deki parklarımızda ve belediyemizin bazı birimlerinde kullandığımız kent mobilyalarını kendi atölyemizde üretiyoruz. Doğa Park’ta yapacağımız Kadın Emeği Pazarı’nın tezgahlarını da özenle kendi atölyemizde ürettik. Tasarruf odaklı bu yaklaşımımızla, hayata geçirdiğimiz projelerle Çekmeköy’de yeni bir dönemin başladığını hep birlikte göreceğiz. Sizlerin güveni ve desteğiyle, güler yüzlü Çekmeköy için bir olacağız, iri olacağız. Hep birlikte daha güçlü bir Çekmeköy inşa edeceğiz!"
    },
    {
        title: "Araç Bakım ve Onarımlarını Kendi Atölyemizde Yapıyoruz",
        image: require("./images/main/aracbakim.png"),
        description: "Bugüne kadar belediyemizde arızalanan tüm araçlar özel sektöre tamir ettiriliyordu ve bazıları kaderine terk edilmişti. Ancak, artık kendi araçlarımızın bakım, onarım ve tamir işlerini, alanında usta olan ekip arkadaşlarımızla gerçekleştiriyoruz. Bu sayede, belediyemizin kasasından bu işler için tek bir kuruş dahi çıkmıyor. Halkın Belediyesi, halkının kaynaklarını en verimli şekilde kullanmaya devam ediyor. Hizmet anlayışımızla, her zaman olduğu gibi halkımızın yanında olmaya devam edeceğiz."
    },
    {
        title: "Pazar Günleri İnşaat Çalışmalarını Yasakladık",
        image: "https://www.cekmekoy.bel.tr/upload/files/zab%C4%B1ta%20in%C5%9Faat%20denetimi/zab%C4%B1ta%20(2).jpg",
        description: "Halk Günü Değil Halkın Günü sloganıyla mahalle mahalle gezerek vatandaşlarımızın taleplerini dinlemeye devam ediyoruz. Ekşioğlu Mahallesi’nde gerçekleştirdiğimiz buluşmada, mahalle sakini Aziz Bolat’ın Pazar günleri inşaat yasağı talebini dikkate aldık ve bu talebi geri çevirmedik. İlçemiz genelinde Pazar günleri inşaat çalışmalarına izin verilmeyecek. Çekmeköylü vatandaşlarımızın huzuru ve rahatlığı için attığımız bu adım ile hafta sonları gürültüsüz ve sakin geçecek. Her zaman sizlerin yanında olmaya ve sorunlarınıza çözümler üretmeye devam edeceğiz."
    },
    {
        title: "Aşevimiz Hizmete Başladı",
        image: require("./images/main/asevi.jpg"),
        description: "Çekmeköy’de ihtiyaç sahibi vatandaşlarımızın yanında olmaya devam ediyoruz. Sosyal dayanışmayı güçlendirmek ve komşularımıza destek olmak amacıyla Aşevimizin açılışını gerçekleştirdik. Günlük 5000 komşumuza, üç öğün sıcak yemek hizmeti sunacak olan aşevimiz, Çekmeköy’de kimsenin yalnız kalmaması için büyük bir adım olacak. Aşevi, hem ihtiyaç sahiplerine destek olacak hem de ilçemizdeki sosyal yardımlaşma ruhunu canlandıracak. İyilik ve paylaşma duygusunu büyütmek için attığımız bu adım, Çekmeköy’e hayırlı olsun!"
    },
    {
        title: "Doğa Park’ta Vatandaşa Hizmet Dönemi Başlıyor",
        image: "https://www.cekmekoy.bel.tr/upload/files/DO%C4%9EA%20PARK%E2%80%99TA%20VATANDA%C5%9EA%20H%C4%B0ZMET%20D%C3%96NEM%C4%B0%20d%C3%BC%C5%9F%C3%BCk/1_8%20-%20Foto.jpg",
        description: "Halka ait alanları halkın yararına kullanılacak işletmelere dönüştürme çalışmalarımız tüm hızıyla devam ediyor. Vatandaşlardan gelen yoğun talepler doğrultusunda, mülkiyeti ve kullanım hakkı belediyemize ait olan, ancak özel işletmeler tarafından kullanılan alanları halka açmak için çalışmalarımıza başladık. Bu kapsamda, Çekmeköy’ün en yoğun noktalarından biri olan Şehit Üsteğmen Arif Kalafat Doğa Parkı’nda yer alan özel işletmenin tahliye sürecini başlattık. Park içerisinde oluşturulacak yeni sosyal tesiste; uygun fiyatlı yemek, çay ve kahve hizmetleri sunulacak, böylece vatandaşlarımız yüksek fiyat politikasından kurtulmuş olacak. Ayrıca, kadınlar için el emeği ürünlerin satışını yapabilecekleri pazar alanı oluşturacağız. Açık ve kapalı olarak planlanan bu alanlarda kadınlar, el emeği ürünlerini ortak daimi satış noktası ve bireysel satış stantları aracılığıyla sunarak aile ekonomilerine katkı sağlayacak. Bunun yanı sıra, elişi ürünleri için ortak üretim atölyesi ve gıda ürünleri için ortak üretim mutfağı da kurulacak. Bu yeni sosyal donatı alanları ile Doğa Park’ı halkımızın faydasına sunarak, Çekmeköy’ü daha yaşanabilir ve erişilebilir bir ilçe haline getirmeyi hedefliyoruz. Her zaman vatandaşımızın yanındayız!"
    },
    {
        title: "İSKİ Cumhuriyet İçme Suyu Arıtma Tesisi Kapasitesini 4 Katına Çıkarıyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/%C4%B0BB%20BA%C5%9EKANI%20%C4%B0MAMO%C4%9ELU%20%C3%87EKMEK%C3%96Y%E2%80%99DE%20TEMEL%20ATMA%20T%C3%96REN%C4%B0NE%20KATILDI/WhatsApp%20Image%202024-04-25%20at%2015-07-08.jpg",
        description: "25 Nisan'da İBB Başkanımız Ekrem İmamoğlu ile Hüseyinli Mahallemizde bulunan İSKİ Cumhuriyet Su Arıtma Tesisi'nin temel atma törenini gerçekleştirdik.        İstanbul’un içme suyu kaynaklarını korumak ve geliştirmek adına 2.3 milyon TL’lik yatırımla İSKİ Cumhuriyet İçme Suyu Arıtma Tesisi’nin 2. kademesinin temelini attık. En ileri su arıtma sistemlerini kullandığımız bu tesisin kapasitesini mevcut 720.000 m3/gün'den 2.880.000 m3/gün’e çıkaracağız. Melen sisteminden gelen ham suyun arıtma kapasitesini %50 artırarak bu yatırımla İstanbul’da su iletimini güvence altına alıyoruz. Terfi merkezi kapasitesinin artışıyla birlikte kentimizin içme suyunu koruma çalışmalarımıza tüm hızımızla devam ediyoruz."
    },
    {
        title: "Çekmeköy’de Devlet Liselerimiz İçin Coşkulu Mezuniyet Törenleri Düzenliyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/2024%20mezuniyet%20haber/mezuniyet%20hikmet%20tevfik%20ataman%20(6).jpg",
        description: "Çekmeköy Belediyesi olarak, ilçemizdeki 15 devlet lisesinde eğitim gören ve bu yıl mezun olacak 2 bin 318 lise son sınıf öğrencimizin mezuniyet törenlerini büyük bir coşkuyla organize ediyoruz. Çekmeköy Belediyesi olarak, gençlerimizin başarılarını kutluyor, gelecekteki tüm adımlarında yanlarında olacağımızı bir kez daha vurguluyoruz!"
    },
    {
        title: "Belediye Girişinde Ücretsiz Çay ve Kahve İkramı Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/news/evinizde-gibi-hissedeceksiniz/evinizde-gibi-hissedeceksiniz_2737.jpeg",
        description: "Çekmeköy Belediyesi ana hizmet binası girişinde vatandaşlarımız için yeni bir hizmete başladık. Evrak, vergi ve çeşitli iş ve işlemler için belediyemizi ziyaret eden vatandaşlarımızın dinlenip keyifle çay ve kahvelerini yudumlayabilecekleri mini büfemizi hizmete açtık. Mesai saatleri içerisinde hizmet verecek olan bu büfeye ek olarak oturma ve dinlenme alanları da oluşturduk. Halkın belediyesi halkının hizmetinde anlayışıyla, vatandaşlarımızın hayatını her alanda kolaylaştırmak için çalışıyoruz. Hizmet kalitemizi artırarak, bekleme sürelerini en aza indirip vatandaşlarımıza daha etkin ve verimli bir hizmet sunmayı hedefliyoruz. Belediyemize gelen komşularımız, artık kendilerini evlerinde gibi hissedecekler."
    }, 
    {
        title: "Şehidimiz Mehmet Ali Horoz’un Vasiyetiyle Tanzanya’da Su Kuyusu Açtık",
        image: "https://www.cekmekoy.bel.tr/upload/files/%C5%9Eehidin%20vasiyeti%20Tanzanya%E2%80%99da%20ger%C3%A7eklesti%20foto/1.jpg",
        description: "Pençe-Kilit Operasyonu bölgesinde şehit olan Piyade Astsubay Çavuş Mehmet Ali Horoz’un, Afrika’da su kuyusu açtırma vasiyeti, Çekmeköy Belediye Başkanı Orhan Çerkez’in girişimleriyle gerçeğe dönüştü. Şehidimizin vasiyeti doğrultusunda, yıllardır Afrika’da 'Akıllı Köy' modeliyle çalışan ve 1 milyondan fazla Afrikalıya geçim olanakları sağlayan Su Savaşçıları Vakfı ile temasa geçtik. Vakfın yer tespitinin ardından, Tanzanya’nın Mgambojuu Köyü’nde su kuyusu açma çalışmalarına 23 Haziran’da başladık. 1.5 ayda tamamlanan çeşmeyle, köydeki yüzler güldü ve şehidimizin anısı yaşatıldı."
    }, 
    {
        title: "Bayram Öncesi Tüm Cami ve Cemevlerini Temizliyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/12.06.2024%20CAM%C4%B0%C4%B0%20TEM%C4%B0ZL%C4%B0%C4%9E%C4%B0/5U8A0281.jpg",
        description: "Çekmeköy Belediyesi olarak, her bayram öncesi ilçemizdeki tüm ibadethanelerin temizliği için kapsamlı bir çalışma başlatıyoruz. Bayramın huzur ve coşkusunu en temiz şekilde yaşatmak için özel olarak oluşturduğumuz temizlik ekibimiz, ilçemizdeki mescitler, camiler ve cemevlerinde titizlikle çalışıyor. Ekibimiz, ibadethanelerin hem iç hem de dış mekanlarını detaylı bir şekilde temizleyerek hijyenik bir ortam sağlıyor. İbadethanelerimizin temizliği ve düzeni, ibadet eden vatandaşlarımız için önceliğimizdir. Çekmeköy’de her bayramı pırıl pırıl ibadethanelerle karşılamaya devam ediyoruz!"
    }, 
    {
        title: "Bayramlarda ve Özel Günlerde Mezarlıklara Bakım ve Ücretsiz Servis Sağlıyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/bayram%20mesaisi/bayram%20mesaisi%20(6).jpg",
        description: "Her bayram ve özel günlerde, mezarlıklarımıza düzenli olarak bakım yapıyoruz ve kabir ziyareti yapmak isteyen vatandaşlarımız için ücretsiz servis hizmeti sağlıyoruz. Bu geleneksel hizmetimizle, vatandaşlarımızın bu önemli günlerde sevdiklerini rahatlıkla ziyaret etmelerini desteklemekteyiz."
    },
    {
        title: "Çocuklarımız İçin Çekmeköy Yaz Spor Okulları'nı Açtık",
        image: "https://www.cekmekoy.bel.tr/upload/files/yaz%20spor%20okullar%C4%B1%20a%C3%A7%C4%B1l%C4%B1%C5%9F%20t%C3%B6reni%202024/yaz%20spor%20okulu%20(6).jpg",
        description: "Yaz tatilinde çocuklarımız sporla dolu bir yaz geçirsin diye Çekmeköy Yaz Spor Okulları’nı hizmete açtık. Basketbol, futbol, voleybol, cimnastik, kick-box, taekwondo, okçuluk ve kort tenisi gibi çeşitli spor dallarını içeren bu okullarımız, görkemli bir törenle kapılarını açtı. Çocuklarımızın hem eğlenip hem de spor yapacakları bu güzel yaz döneminde, onların gelişimini desteklemek için yanlarında olmaya devam ediyoruz. Tüm çocuklarımıza keyifli ve başarılı bir yaz diliyorum!"
    },
    {
        title: "Yaya Güvenliği İçin Kaldırım İşgallerine Sıkı Denetim Yapıyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/KALDIRIM%20%C4%B0%C5%9EGAL%C4%B0NE%20ZABITA%20DENET%C4%B0M%C4%B0%20d%C3%BC%C5%9F%C3%BCk/FERT6638.jpg",
        description: "Yaya hareketliliğinin yoğun olduğu cadde ve sokaklarda, kaldırımları işgal eden işyerlerine yönelik kontrollerimiz devam ediyor. Zabıta ekiplerimiz, kanun ve mevzuata aykırı şekilde ticari malzeme ve gıda ürünlerini kaldırım ve yol kenarına yerleştiren işyerlerini uyararak, yaya yollarında aksaklık yaşanmaması için esnafımızı kurallara uymaya davet ediyor. Kaldırımların yayalar için güvenli bir şekilde kullanılabilmesi adına denetimlerimizi titizlikle sürdürüyor ve halkımızın güvenliği için çalışmaya devam ediyoruz."
    },
    {
        title: "Evde Temizlik Hizmetlerimiz Tüm Hızıyla Devam Ediyor",
        image: require("./images/main/temizlik.jpg"),
        description: "Çekmeköy Belediyesi olarak, ihtiyaç sahibi vatandaşlarımız için başlattığımız evde temizlik hizmetlerimiz hız kesmeden devam ediyor. Ekiplerimiz, yaşlı, engelli ve bakıma muhtaç komşularımızın evlerini özenle temizleyerek, onların yaşam alanlarını hijyenik ve sağlıklı tutmak için çalışıyor. Sosyal belediyecilik anlayışımızla, her zaman vatandaşlarımızın yanında olmaya devam ediyoruz. Çekmeköy’de kimse kendini yalnız hissetmesin diye tüm gücümüzle hizmete devam ediyoruz!"
    },
    {
        title: "Okullarımızın Boya, Bakım ve Onarım Çalışmalarını Yapıyoruz",
        image: require("./images/main/baskanboya.png"),
        description: "Çekmeköy’deki okullarımızın daha modern ve güvenli bir eğitim ortamına kavuşması için boya, bakım ve onarım çalışmalarımıza hız kesmeden devam ediyoruz. Okullarımızın fiziki koşullarını iyileştirerek, öğrencilerimize temiz ve sağlıklı bir eğitim ortamı sunmayı amaçlıyoruz. Her okulun ihtiyaçlarına özel olarak gerçekleştirdiğimiz bu çalışmalarla, eğitim kalitesine katkı sağlamayı hedefliyoruz. Geleceğimizin teminatı olan çocuklarımızın en iyi şartlarda eğitim alabilmesi için çalışmaya devam edeceğiz. Çekmeköy’deki tüm okullarımıza hayırlı olsun!"
    },
    {
        title: "Üniversite Tercih Dönemlerinde Gençlerimize Tercih Danışmanlık Desteği Veriyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/%C3%9CN%C4%B0VERS%C4%B0TE%20ADAYLARINA%20TERC%C4%B0H%20DANI%C5%9EMANLIK%20DESTE%C4%9E%C4%B0%20BA%C5%9ELADI%20d%C3%BC%C5%9F%C3%BCk/5U8A6290.jpg",
        description: "Üniversite sınav sonuçlarının açıklanmasının ardından, üniversite adaylarının doğru tercih yapmalarına destek olmak amacıyla Üniversite Tercih Danışmanlık ve Rehberlik Merkezi’ni hizmete açtık. Şehit Üsteğmen Arif Kalafat Doğa Park ve Madenler Meydanı’nda kurduğumuz merkezlerde, YKS ve Yükseköğretim Kurumu yönetmelikleri konusunda uzman danışmanlarımız, adayların bilinçli ve doğru tercihler yapmalarına yardımcı oluyor. Ayrıca, ilgi alanlarına yönelik rehberlik hizmeti sunarak gençlerimizin geleceklerine yön vermelerine katkı sağlıyoruz. Üniversite adaylarımızın en iyi şekilde desteklenmesi için buradayız!"
    },
    {
        title: "Genç Çiftlere Evlilik Danışmanlığı Hizmetimiz Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/files/Genç%20Çiftlere%20Evlilik%20Danışmanlığı%20SOSYAL%20MEDYA%20-01.jpg",
        description: "Çekmeköy Belediyesi Psikolojik Danışmanlık Merkezi, hizmet ağını genişleterek, genç çiftler için yeni bir danışmanlık hizmeti başlattı. Biz Olma Yolunda adıyla sunulan bu hizmet, aile kurma sürecindeki genç çiftlerimize evlilik dinamiklerini ve aile bağlarını güçlendirme konusunda destek olmayı amaçlıyor. Uzman psikologlarımız ve çift terapistlerimiz, sevgi, saygı, hoşgörü ve aile bağları gibi temel konularda çiftlere rehberlik ederek, sağlıklı ve huzurlu bir aile ortamı oluşturmalarına yardımcı olacaklar. Bu ücretsiz danışmanlık hizmetinden faydalanmak isteyen genç çiftler, 0216 600 0 600 numaralı Çekmeköy Belediyesi Çağrı Merkezi’nin 2720 / 2721 / 2780 / 2792 dahili numaralarını arayarak randevu alabilirler. Çekmeköy Belediyesi olarak, toplumun temel yapı taşı olan ailelerin güçlü ve sağlıklı bir şekilde kurulmasına destek vermeye devam ediyoruz. Biz Olma Yolunda ilk adımı atan tüm çiftlerimizi bu hizmetten faydalanmaya davet ediyoruz!"
    },
]

export default project