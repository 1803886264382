const project = [
    {
        title: "4 Mahallemizde Ücretsiz Öğrenci Servisleri Başladı",
        image: "https://www.cekmekoy.bel.tr/upload/files/k%C3%B6ylere%20%C3%BCcretsiz%20servis/servis%20(1).jpg",
        description: "Ömerli, Koçullu, Sırapınar ve Hüseyinli mahallelerimizde vatandaşlarımızla bir araya gelerek, öğrencilerimizin okullarına ulaşımda yaşadığı sorunları dinledik. Eğitim aksatılmamalı dedik ve hafta içi her sabah saat 06:30’da mahalle meydanları ve muhtarlıklar önünden Madenler Meydanı’na ücretsiz öğrenci servisleri başlattık. Gençler bizim başımızın tacı; onların yanındayız ve her zaman destekçileri olacağız. Öğrenci kardeşlerimize hayırlı olsun!"
    },
    {
        title: "İSKİ Cumhuriyet İçme Suyu Arıtma Tesisi Kapasitesini 4 Katına Çıkarıyoruz",
        image: "https://www.cekmekoy.bel.tr/upload/files/%C4%B0BB%20BA%C5%9EKANI%20%C4%B0MAMO%C4%9ELU%20%C3%87EKMEK%C3%96Y%E2%80%99DE%20TEMEL%20ATMA%20T%C3%96REN%C4%B0NE%20KATILDI/WhatsApp%20Image%202024-04-25%20at%2015-07-08.jpg",
        description: "25 Nisan'da İBB Başkanımız Ekrem İmamoğlu ile Hüseyinli Mahallemizde bulunan İSKİ Cumhuriyet Su Arıtma Tesisi'nin temel atma törenini gerçekleştirdik.        İstanbul’un içme suyu kaynaklarını korumak ve geliştirmek adına 2.3 milyon TL’lik yatırımla İSKİ Cumhuriyet İçme Suyu Arıtma Tesisi’nin 2. kademesinin temelini attık. En ileri su arıtma sistemlerini kullandığımız bu tesisin kapasitesini mevcut 720.000 m3/gün'den 2.880.000 m3/gün’e çıkaracağız. Melen sisteminden gelen ham suyun arıtma kapasitesini %50 artırarak bu yatırımla İstanbul’da su iletimini güvence altına alıyoruz. Terfi merkezi kapasitesinin artışıyla birlikte kentimizin içme suyunu koruma çalışmalarımıza tüm hızımızla devam ediyoruz."
    },
    {
        title: "Hüseyinli ve Sırapınar Mahallelerinde Komşularımızın Taleplerini Dinledik",
        image: "https://www.cekmekoy.bel.tr/upload/files/s%C4%B1rap%C4%B1nar%20h%C3%BCseyinli%20halk%20bulusmasi/5U8A8894.jpg",
        description: "Halk Günü Değil, Halkın Günü sloganıyla başlattığımız mahalle buluşmalarının devamında, Hüseyinli ve Sırapınar Mahalle sakinleriyle Sırapınar Köy Konağı’nda bir araya geldik. Toplantıya belediye başkan yardımcıları, birim müdürleri, Hüseyinli Muhtarı İlhan Kılınç, Sırapınar Muhtarı Nejat Girgin ve vatandaşlarımız katıldı. İnternet altyapısı, sokak hayvanları, imar durumu, çevre, spor alanları, parklar, eğitim, ulaşım, sinek ilaçlaması ve sağlık ocağı gibi konularda gelen istek, talep ve şikayetleri tek tek not aldık. Vatandaşlarımızla buluşmada, Siyaset hizmet aracıdır. Eğer iyi yaparsanız hizmete dönüşür, herkesi memnun eder diyerek, eski yönetimin yerine gelmemizin ardından bu sorunları çözmek için gerekli adımları atacağımızı belirttik. Bölgedeki sorunlar için gerekli görüşmeleri yapacağımızı ve istihdam konusunda da gerekli hamleleri yapacağımızı ifade ettik. Vatandaşlarımıza bu süreçte gösterdikleri ilgi ve destek için teşekkür ediyorum, sorunları birlikte çözeceğiz."
    }
]

export default project