const project = [
    {
        title: "Yaşar Doğu Parkı'ndaki Yeni Sosyal Tesisimiz Yakında Hizmetinizde",
        image: require("./images/mimarsinan/yasardogusosyaltesis.JPG"),
        description: "Çekmeköy’e değer katmaya devam ediyoruz! Yaşar Doğu Parkı'nda açacağımız sosyal tesisimiz çok yakında siz değerli vatandaşlarımızın hizmetinde olacak. Modern ve konforlu bir şekilde tasarlanan tesisimiz, parkın huzurlu atmosferinde keyifli vakit geçirmeniz için tüm detaylarıyla hazırlandı. Yemyeşil bir ortamda çayınızı yudumlayıp dostlarınızla buluşabileceğiniz bu yeni alan, sosyal hayatınıza renk katacak."
    },
    {
        title: "Yaşar Doğu Parkımız Yenilendi",
        image: "https://www.cekmekoy.bel.tr/upload/files/--ya%C5%9Far%20do%C4%9Fur%20park%C4%B1/5U8A0442%20(1).jpg",
        description: "Vatandaşlarımızdan gelen talepleri bir bir hayata geçirmeye devam ediyoruz. Mimar Sinan Mahallesi, Orhangazi Caddesi’nde bulunan Yaşar Doğu Parkı, mahalle sakinlerimizin istek ve beklentileri doğrultusunda yenilendi. Çekmeköy Belediyesi Park ve Bahçeler Müdürlüğü tarafından yapılan yenileme ve çevre düzenleme çalışmaları kapsamında, çocukların ve ailelerinin daha keyifli vakit geçirebilmeleri için parkın tamamı elden geçirildi ve yeni oyun grupları eklendi. Ayrıca parkta eksiklikler giderilerek, çeşitli aktivite alanları da oluşturuldu. Yaşar Doğu Parkı’nı yenileyerek, mahalle sakinlerimize daha güzel ve kullanışlı bir alan kazandırmanın mutluluğunu yaşıyoruz."
    },
    {
        title: "Ord. Prof. Zeki Velidi Togan Parkı'mızın Benzin İstasyonuna Dönüştürülmesini Engelledik",
        image: "https://www.cekmekoy.bel.tr/upload/files/ZEK%C4%B0%20VEL%C4%B0D%C4%B0%20TOGAN%20PARKI/park%20(3).JPG",
        description: "Olağanüstü topladığımız meclisle, geçtiğimiz dönemde Mimar Sinan Mahallesi'nde benzin istasyonuna dönüştürülmek istenilen Ord.Prof. Zeki Velidi Togan Parkı'nın yetkisini alarak, parkın benzin istasyonuna dönüştürülme fikrini tamamen ortadan kaldırdık."
    },
]

export default project